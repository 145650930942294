import { useSetDocumentTitle } from 'hooks/useSetDocumentTitle';
import type { CmsApiPagePage } from 'modules/cms/api/types/CmsApiPage';
import { useCmsContentPageScrollHandler } from 'modules/cms/hooks/useCmsContentPageScrollHandler';

import { CmsPagePageBody } from './CmsPagePageBody';
import { CmsPagePageLayout } from './CmsPagePageLayout';

type Props = {
  content: CmsApiPagePage;
};

export function CmsPagePage({ content }: Props) {
  useSetDocumentTitle(content.headTitle || content.title);
  useCmsContentPageScrollHandler();

  return (
    <CmsPagePageLayout content={content}>
      <CmsPagePageBody content={content} />
    </CmsPagePageLayout>
  );
}
